import Vue from 'vue';
import arrowDown from './arrow-down.svg';
import arrowPrev from './arrow-prev.svg';
import arrowNext from './arrow-next.svg';
import bill from './bill.svg';
import chat from './chat.svg';
import check from './check.svg';
import close from './close.svg';
import emails from './emails.svg';
import applicants from './applicants.svg';
import eyeoff from './eyeoff.svg';
import eyeon from './eyeon.svg';
import logout from './logout.svg';
import moon from './moon.svg';
import nodata from './nodata.svg';
import search from './search.svg';
import trainging from './trainging.svg';
import logoDark from './logo-dark.svg';
import users from './users.svg';
import skills from './skills.svg';
import bag from './bag.svg';
import jobsBag from './jobs-bag.svg';
import location from './location.svg';
import clock from './clock.svg';
import file from './file.svg';
import download from './download.svg';
import company from './company.svg';
import government from './government.svg';
import individual from './individual.svg';
import organization from './organization.svg';
import refresh from './refresh.svg';

// HALABK / IMPORTS - START
import tick from './tick.svg';
import trash from './trash.svg';
import falseCheck from './false-check.svg';
import trueCheck from './true-check.svg';
import filter from './filter.svg';
import logout2 from './logout2.svg';
import element from './element.svg';
import calender from './calendar.svg';
import calenderMain from './calendar-main.svg';
import money from './money.svg';
import statusUp from './status-up.svg';
import messages from './messages.svg';
import home from './home.svg';
import setting from './setting.svg';
import ticketDiscount from './ticket-discount.svg';
import star from './star.svg';
import menu from './menu.svg';
import panel1 from './panel1.svg';
import panel2 from './panel2.svg';
import panel3 from './panel3.svg';
import panel4 from './panel4.svg';
import starFill from './star-fill.svg';
import locationFill from './location-fill.svg';
import addCircle from './add-circle.svg';
import addFilldCircle from './add-filled-circle.svg';
import verticalDots from './vertical-dots.svg';
import sortable from './sortable.svg';
import packages from './packages.svg';
import logo from './logo.svg';
import LogoImage from './logo-image.svg';
import lang from './lang.svg';
import email from './email.svg';
import lock from './lock.svg';
import arrowNextCircle from './arrow-next-circle.svg';
import cirlcePrev from './circle-prev.svg';
import stop from './stop.svg';
import chat2 from './chat2.svg';
import notification from './notification.svg';
import loginSvg1 from './login-svg-1.svg';
import loginSvg2 from './login-svg-2.svg';
import loginSvg3 from './login-svg-3.svg';
import imgClose from './img-close.svg';
import imgUpload from './img-upload.svg';
import fileUpload from './file-upload.svg';
import notification2 from './notification-icon.svg';
import warning from './warning.svg';
import bill2 from './bill2.svg';
import timer from './timer.svg';
import map from './map.svg';
import gps from './gps.svg';
import arrowCircleLeft2 from './arrow-circle-left.svg';
import crown from './crown.svg';
import forbidden from './forbidden.svg';
import tickCircle from './tick-circle.svg';
import story from './story.svg';
import danger from './danger.svg';
import tickSquire from './tick-square.svg';
import messageNotif from './message-notif.svg';
import closeHeader from './close-header-icon.svg';
import searchSM from './search-sm.svg';
import send3 from './send3.svg';
import alertWarning from './alert-warning.svg';
import block from './block.svg';
import ar from './ar.svg';
import en from './en.svg';
// HALABK / IMPORTS - END

// HALABK / ICON COMPONENTS - START
Vue.component('alert-warning-icon', alertWarning);
Vue.component('send3-icon', send3);
Vue.component('search-sm-icon', searchSM);
Vue.component('close-header-icon', closeHeader);
Vue.component('message-notif-icon', messageNotif);
Vue.component('story-icon', story);
Vue.component('danger-icon', danger);
Vue.component('tick-squire-icon', tickSquire);
Vue.component('tick-circle-icon', tickCircle);
Vue.component('forbidden-icon', forbidden);
Vue.component('crown-icon', crown);
Vue.component('arrow-circle-left-2-icon', arrowCircleLeft2);
Vue.component('gps-icon', gps);
Vue.component('map-address-icon', map);
Vue.component('timer-icon', timer);
Vue.component('bill2-icon', bill2);
Vue.component('chat2-icon', chat2);
Vue.component('tick-icon', tick);
Vue.component('add-filled-circle-icon', addFilldCircle);
Vue.component('trash-icon', trash);
Vue.component('true-check-icon', trueCheck);
Vue.component('false-check-icon', falseCheck);
Vue.component('add-circle-icon', addCircle);
Vue.component('location-fill-icon', locationFill);
Vue.component('star-fill-icon', starFill);
Vue.component('panel1-icon', panel1);
Vue.component('panel2-icon', panel2);
Vue.component('panel3-icon', panel3);
Vue.component('panel4-icon', panel4);
Vue.component('element-icon', element);
Vue.component('calender-icon', calender);
Vue.component('calender-main-icon', calenderMain);
Vue.component('money-icon', money);
Vue.component('star-icon', star);
Vue.component('ticket-discount-icon', ticketDiscount);
Vue.component('status-up-icon', statusUp);
Vue.component('messages-icon', messages);
Vue.component('home-icon', home);
Vue.component('setting-icon', setting);
Vue.component('menu-icon', menu);
Vue.component('logout2-icon', logout2);
Vue.component('filter-icon', filter);
Vue.component('vertical-dots-icon', verticalDots);
Vue.component('sortable-icon', sortable);
Vue.component('packages-icon', packages);
Vue.component('logo-light', logo);
Vue.component('logo-image-icon', LogoImage);
Vue.component('lang-icon', lang);
Vue.component('email-icon', email);
Vue.component('lock-icon', lock);
Vue.component('arrow-next-circle-icon', arrowNextCircle);
Vue.component('circle-prev-icon', cirlcePrev);
Vue.component('stop-icon', stop);
Vue.component('notification-icon', notification);
// HALABK / ICON COMPONENTS - END

Vue.component('arrow-down-icon', arrowDown);
Vue.component('bill-icon', bill);
Vue.component('chat-icon', chat);
Vue.component('check-icon', check);
Vue.component('close-icon', close);
Vue.component('emails-icon', emails);
Vue.component('applicants-icon', applicants);
Vue.component('eyeoff-icon', eyeoff);
Vue.component('eyeon-icon', eyeon);
Vue.component('logout-icon', logout);
Vue.component('moon-icon', moon);
Vue.component('nodata-icon', nodata);
Vue.component('search-icon', search);
Vue.component('trainging-icon', trainging);
Vue.component('logo-dark', logoDark);
Vue.component('users-icon', users);
Vue.component('skills-icon', skills);
Vue.component('bag-icon', bag);
Vue.component('jobs-bag-icon', jobsBag);
Vue.component('location-icon', location);
Vue.component('clock-icon', clock);
Vue.component('file-icon', file);
Vue.component('download-icon', download);
Vue.component('arrow-prev-icon', arrowPrev);
Vue.component('arrow-next-icon', arrowNext);
Vue.component('company-icon', company);
Vue.component('government-icon', government);
Vue.component('individual-icon', individual);
Vue.component('organization-icon', organization);
Vue.component('login-svg1', loginSvg1);
Vue.component('login-svg2', loginSvg2);
Vue.component('login-svg3', loginSvg3);
Vue.component('img-close-icon', imgClose);
Vue.component('img-upload-icon', imgUpload);
Vue.component('file-upload-icon', fileUpload);
Vue.component('notification2-icon', notification2);
Vue.component('warning-icon', warning);
Vue.component('block-icon', block);
Vue.component('ar-icon', ar);
Vue.component('en-icon', en);
Vue.component('refresh-icon', refresh);
