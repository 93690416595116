export default {
  allPackageDropdown: null,
  allInterests: null,
  allCountries: null,
  allSaudiCities: null,
  allAccountTypes: [
    {
      id: 1,
      title: 'first',
    },
    {
      id: 2,
      title: 'second',
    },
  ],
  notifications: null,
  subscriptions: null,
};
